<template>
	<v-sheet flat>
		<h2>Projects</h2>

		<v-card class="rounded-lg" color="lightest">
			<v-card-text>
				<p>
					Use the filters to search for past, present and future
					appointments for your organisation.
				</p>
				<filter-appointments v-model="filter" :project-items="projectItems"></filter-appointments>
			</v-card-text>
		</v-card>
		<v-text-field label="Search" append-icon="mdi-magnify"></v-text-field>

		<v-data-table :loading="loading" :items="appointments" :headers="headers">
			<template v-slot:item.appointment_project="{ item }">
				<project-name :value="item.appointment_project"></project-name>
			</template>
			<template v-slot:item.user="{item}">
				{{getUser(item)}}
			</template>
			<template v-slot:item.appointment_start="{ item }">
				<date
					:value="item.appointment_start"
				></date>
			</template>
			<template v-slot:footer.prepend>
				<export-button
					:columns="headers"
					:rows="downloadRows"
					name="MW-Export"
				></export-button>
			</template>
		</v-data-table>
	</v-sheet>
</template>

<script>
import FilterAppointments from "@c/contracts/Filter";
import ExportButton from "@c/contracts/Export";
import ProjectName from "@c/contracts/ProjectName";
import Date from "@c/contracts/Date";
export default {
	name: "Projects",
	data: () => {
		return {
			loading: false,
			filter: {
				projects: [],
				start: null,
				end: null,
				appointment_status: null,
			},
		};
	},
	computed: {
		organisation() {
			return this.$store.state.organisation.data.id;
		},
		appointments() {
			// return this.$store.getters["singleAppointments/all"];
			return this.$store.getters["singleAppointments/getByFilters"](
				this.filter
			);
		},
		projectItems() {
			return Object.values(this.$store.state.projects.data)
				.filter((p) => !p.incentive_only)
				.map((p) => {
					return {
						value: p.id,
						text: p.client_name,
					};
				});
		},
		headers() {
			return [
				{ value: "appointment_project", text: "Project" },
				{ value: "user", text: "Name" },
				{ value: "appointment_methodology", text: "Methodology" },
				{ value: "appointment_start", text: "Start" },
				{ value: "appointment_status", text: "Status" },
			];
		},
		projects() {
			return this.$store.state.projects.data;
		},
		users(){
			return this.$store.state.users.data
		},
		downloadRows() {
			const self = this;
			let rows = self.appointments;
			rows = rows.map((r) => {
				return {
					user: self.getUser(r),
					appointment_start: self.getStart(r),
					appointment_project: self.getProjectName(r),
					appointment_status: r.appointment_status
				}
			});
			return rows;
		},
	},
	components: {
		FilterAppointments,
		ExportButton,
		Date,
		ProjectName,
	},
	watch: {
		organisation: {
			immediate: true,
			handler(v) {
				if (v) {
					this.$store.dispatch("projects/fetchAll", true);
				}
			},
		},
		filter() {
			this.loading = true;
			this.$store
				.dispatch("singleAppointments/fetchByFilter", this.filter)
				.then(() => {
					this.loading = false;
				});
		},
	},
	methods: {
		getUser(item){
			if( !item.user ){
				return ""
			}
			if( !this.users[item.user] ){
				return  "";
			}
			return this.users[item.user].first_name || "";
		},
		getProjectName(item) {
			if (!item.appointment_project) {
				return "";
			}
			if (!this.projects[item.appointment_project]) {
				return "";
			}
			return this.projects[item.appointment_project].client_name || "";
		},
		getStart(item) {
			try {
				let date = { ...item.appointment_start };
				date = date.toDate();
				return `${date.toLocaleDateString()} - ${date.toLocaleTimeString(
					{},
					{ hour: "2-digit", minute: "2-digit" }
				)}`;
			} catch (error) {
				return "";
			}
		},
	},
};
</script>
